@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.word-break{
  word-break: break-word;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #dfe9fa transparent; 
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dfe9fa;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.dark .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #131313 transparent; 
}

.dark .custom-scrollbar::-webkit-scrollbar {
  width: 6px; 
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #131313;
  border-radius: 4px;
}

.dark .custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
canvas{
  height: 244px !important;
  width: 100% !important;
}

#bar-chart{
  width: 90% !important;
}
